/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8b1a74fa-995e-4923-9679-35bb6bece081",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yoSI6WZnM",
    "aws_user_pools_web_client_id": "2sbi0i6er3sgpk6682r80da4me",
    "oauth": {}
};


export default awsmobile;
